.content-top {
    background-color: #3E3F3A;
    height: 140px;
}

.content-image-top {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 20px;
}

.image-properties {
    height: 60px;
    max-height: 50px;
    max-width: 50px;
    padding-right: 10px;
    color: #3E3F3A;
}

.content-copyright {
    display: flex;
    padding-top: 45px;
    justify-content: center;
    color: #d9d9d9;
}