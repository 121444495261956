.page-container {    
    width: 100%;
}

.page-introduction {
    display: flex;
    flex-direction: row;    
    max-width: 1900px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}

.introduction-picture {
    padding-left: 30px;
    padding-top: 30px;
    height: 335px;
}

.introduction-details {
    padding-left: 70px;
    display: flex;
    flex-direction: column;
}

.introduction-title {
    padding-top: 25px;
    font-family: serif;
    font-weight: 900;
    font-size: 50px;
}

.introduction-subtitle {
    font-size: 25px;
    padding-bottom: 30px;
}

.introduction-description {
    padding-right: 5%;
    font-size: 16px;    
}

.introduction-details-border {
    border-top: 3px solid orange;
    width: calc(100% - 50px);
    padding-top: 30px;
    
}

.introduction-goto-top {
    display: flex;
    flex-direction: row;
    padding-top: 13px;
    padding-left: 385px;
}

.introduction-goto-link {
    padding-right: 10px;
    padding-bottom: 15px;
}

.section-border {
    border-top: 3px solid #ffa500;
    max-width: 150px;
}

.footer-at-bottom {
    height: 100%;
    min-height: calc(100vh - 197px);
}

/* MEDIA - MOBILE OVERRIDE */
@media only screen and (max-width: 1224px) {  
    
    .page-introduction {
        display: flex;
        flex-direction: column;
        justify-content: center;   
        max-width: 1900px;
        width: 100%;
    }

    .introduction-picture {
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding-top: 15px;

        width: unset !important;
        padding-left: unset !important;
        height: unset !important;
    }

    .introduction-details {
        padding-left: 3%;
        padding-right: 3%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .introduction-title {
        padding-top: 25px;
        font-family: serif;
        font-weight: 900;
        font-size: 30px;
    }

    .introduction-subtitle {
        font-size: 20px;
        padding-bottom: 12px;
    }

    .introduction-goto-top {
        padding-left: unset !important;

        margin-left: auto;
        margin-right: auto;
    }

    .section-border {
        border-top: 2px solid #ffa500;
        max-width: unset !important
    }
}

/* ----------------------------------------*/
/* BANNER SECTION */
.bannersec-top {
    display: flex;
    flex-direction: row;   
}

.bannersec-title {
    display: flex;
    flex-direction: column;
    padding-left: 35px;
    padding-right: 40px;
    padding-top: 30px;
    text-transform: uppercase;    
    background-color: #3E3F3A;
    color: #ffffff;

    font-family: Georgia, 'Times New Roman', Times, serif;
}

.bannersec-body {
    background-color: #3E3F3A;
    padding-top: 30px;
    padding-right: 80px;
    padding-bottom: 30px;
    color: #bfbfbf;
    font-size: 14px;

    width: 100%;
}

/* MEDIA - MOBILE OVERRIDE */
@media only screen and (max-width: 1224px) {
    .bannersec-top {
        flex-direction: column;
    }

    .bannersec-title {
        padding-left: 25px;
        padding-top: 12px;
        text-transform: uppercase;
    
        font-family: Georgia, 'Times New Roman', Times, serif;
    }

    .bannersec-body {
        background-color: #3E3F3A;
        padding-top: 8px;
        padding-left: 25px;
        padding-right: 5%;
        padding-bottom: 30px;
        font-size: 14px;
    
        width: 100%;
    }
}
/* ----------------------------------------*/

/* ----------------------------------------*/
/* CONTENT SECTION */
.content-title-top {
    display: flex;
    flex-direction: column;

    padding-left: 15px;
    padding-top: 12px;
    text-transform: uppercase;

    font-family: Georgia, 'Times New Roman', Times, serif;
}

.content-body-top {
    padding-left: 5%;
    padding-top: 10px;
    width: 100%;
}

.content-title {
    font-family: serif;
    font-weight: 900;
    font-size: 28px;
    color: #3E3F3A;
}

.content-border {
    border-top: 1px solid orange;
    width: 90%;
    padding-top: 8px;
}

.content-subborder {
    border-top: 1px solid #cfcfcf;
    width: 40%;
    min-width: 250px;
    padding-top: 8px;
}

.content-subtitle-rowgroup-top {
    display: flex;
    flex-direction: row;
}

.content-subtitle-rowgroup-bolditalic {
    font-style: italic;
    font-size: 16px;
    padding-right: 8px;
    font-weight: bold;
}

.content-subtitle-rowgroup-details {
    font-size: 16px;
    padding-bottom: 5px;
}

.content-body {
    font-size: 16px;
    padding-right: 10%;

    overflow-wrap: break-word;
    word-wrap: break-word;
}

.content-body-section-title {
    font-size: 16px;
    font-weight: 700;
    text-decoration: underline;
}

.section-end {
    padding-bottom: 25px;
}

/* MEDIA - MOBILE OVERRIDE */
@media only screen and (max-width: 1224px) {
    .content-title-top {
        padding-top: 2px;
    }

    .content-body-top {
        padding-left: 1%;
    }

    .content-title {
        font-size: 24px;
    }

    .content-body {
        font-size: 16px;
        padding-right: 5%;
    }

    .section-end {
        padding-bottom: 12px;
    }
}

/* ----------------------------------------*/
/* AUTO SCROLL */
.scrollTop {
    position: fixed; 
    width: 100%;
    bottom: 20px;
    align-items: center;
    height: 20px;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
    animation: fadeIn 0.3s;
    transition: opacity 0.6s;
    opacity: 0.7;
    color: #ffa500;
}

.scrollTop:hover{
    opacity: 1;
}
  
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.5;
    }
}