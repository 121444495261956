.poi-top {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    max-width: 1900px;
}

.poi-container {
    display: flex;
    flex-direction: row;
    width: 675px;
}

.poi-image {
    display: block;
    padding-top: 5px;
    
    min-width: 50px;
    width: 50px;
    height: 50px;
}

.poi-details {
    width: 800px;
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-bottom: 20px;
    padding-right: 20px;
    font-size: 15px;
}

.poi-title {
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 18px;
    font-weight: 900;
    color: #ffffff;
    text-transform: uppercase;    
    padding-bottom: 5px;
}

.poi-desc {
    color: #d9d9d9;
}

/* MEDIA - MOBILE OVERRIDE */
@media only screen and (max-width: 1735px) {
    .poi-container {
        display: flex;
        flex-direction: row;
        width: unset !important;
        min-width: 375px;
    }

    .poi-details {
        width: unset !important;
        display: flex;
        flex-direction: column;
        padding-left: 10px;
        padding-bottom: 20px;
        padding-right: 20px;
        font-size: 14px
    }
}