.searchbar-containbtn-top {
    padding-left: 9%;
}

.searchbar-top {
    display: flex;
    flex-direction: row;
    width: 95%;
    min-width: 310px;
    max-width: 1400px;

    padding-left: 9%;
}

.searchbar-select {    
    padding-right: 20px;
    width: 100%;
}

.search-count-top {
    display: flex;
    flex-direction: row;

    padding-left: 9%;
}

.search-count-total {
    font-size: 56px;
    color: #ffa500;
}

.search-count-total-desc {
    font-size: 24px;
    padding-left: 10px;
    padding-top: 14px;
}

/* MEDIA - MOBILE OVERRIDE */
@media only screen and (max-width: 1224px) {
    .searchbar-containbtn-top {
        padding-left: unset !important;
    }

    .searchbar-top {
        padding-left: unset !important;
    }

    .search-count-top {
        padding-left: unset !important;
    }
}
/* ----------------------------------------*/
/* SEARCH RESULTS SECTION */
.project-daterow {
    font-size: 16px;
    padding-bottom: 5px;
}

.keywordtag-top {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 5px;
    padding-right: 20px;
}

.project-results-body-top {
    padding-top: 10px;
    width: 100%;
}